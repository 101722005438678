<template>
  <v-app>

<v-container >
<div class="pl-lg-16 ml-lg-8" align="left" justify="left">
<v-img class="align-center " src="./assets/z.svg" width="3rem"></v-img>
<h3 class="pt-2 pl-2">zLITE - Access Charges Subscription</h3>
<!-- <v-divider ></v-divider> -->
</div>
</v-container>
    <v-main>
      <Subscription/>
    </v-main>
<v-container
    padless
>

  </v-container>
<v-footer
      v-bind="localAttrs"
      :padless="padless"
      style="padding:0;padding"
    >
   
      <v-card
        tile
        width="100%"
        class="text-left pl-lg-24 pr-lg-12"
      >
      
        <v-divider></v-divider>
      <v-container padless style="padding:0">
        <v-card-text class="pl-lg-16 ml-lg-16" style="font-size:10px;" >
            > Zebu © 2021, All rights reserved.<br>
            Zebu Share and Wealth Managements Pvt Ltd, NSE / BSE / MCX - SEBI Registration No: INZ000174634, CDSL : 12080400, AMFI ARN : 113118, Research Analyst : INH200006044
        </v-card-text>
      </v-container>
      </v-card>
    
    </v-footer>
  </v-app>
</template>

<script>
import Subscription from './components/Subscription';

export default {
    name: 'App',    
    metaInfo() {
        return { 
            title: "zLite Subscription",
            meta: [
                { name: 'description', content:  'zLite subscription plan'},
                { property: 'og:title', content: "zLite Subscription"},
                { property: 'og:site_name', content: 'Zebu'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    data: () => ({
     
      padless: false,
      variant: 'default',
    }),
    components: {
        Subscription,
      },
    computed: {
      localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },
      
    },
    
}
</script>
