<template>
  <v-container>
    <v-item-group mandatory>
      <v-container class="mb-6">
        <v-row class="d-flex justify-center">
          <!-- Card one -->
          <v-col cols="12" lg="2">
            <v-item v-slot="{ active, toggle }">
              <v-card
                  :color="
                  active ? 'primary white--text text--lighten-1 ' : 'white'"
                  v-on:click="subPlan='zLITE 1',price='2500',validity='6 months'"
                  @click="toggle"
                  
                >
                <v-card-title class="pb-2">
                  <v-row>
                    <v-col cols="4" lg="12" class="h3 pt-1 pb-0 text-center">
                      <!-- <v-avatar color="primary" rounded size="30">1</v-avatar> -->
                      <h4 class="pt-2">zLITE 1</h4>
                    </v-col>
                    <v-col
                      cols="8"
                      lg="12"
                      class="float-right mt-1 pt-1 pb-0 text-end text-lg-center">
                      &#8377; 2500
                      <p class="caption" style="margin-top: -5px">
                        &nbsp;(6 months)
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-title class="pb-2">
                  <v-row>
                    <!-- v-bind:class="{ active: isActive }" style="color:white" -->
                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.01%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Intraday<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.1%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Delivery<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>&#8377;20</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Options<small>*</small>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
          <!--  card one end -->

          <!-- Card two -->
          <v-col cols="12" lg="2">
            <v-item v-slot="{ active, toggle }">
              <v-card
                v-model="subPlan"
                :color="
                  active ? 'primary white--text text--lighten-1 ' : 'white'
                "
                v-on:click="subPlan='zLITE 2',price='5000',validity='6 months'"
                @click="toggle">
                <v-card-title class="pb-2">
                  <v-row>
                    <v-col cols="4" lg="12" class="h3 pt-1 pb-0 text-center">
                      <!-- <v-avatar color="primary" rounded size="30">1</v-avatar> -->
                      <h4 class="pt-2">zLITE 2</h4>
                    </v-col>
                    <v-col
                      cols="8"
                      lg="12"
                      class="float-right mt-1 pt-1 pb-0 text-end text-lg-center">
                      &#8377; 5000
                      <p class="caption" style="margin-top: -5px">
                        &nbsp;(6 months)
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-title class="pb-2">
                  <v-row>
                    <!-- v-bind:class="{ active: isActive }" style="color:white" -->
                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.0075%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Intraday<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.075%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Delivery<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>&#8377;15</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Options<small>*</small>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
          <!-- Card two end -->

          <!-- card three -->
          <v-col cols="12" lg="2">
            <v-item v-slot="{ active, toggle }">
              <v-card
                :color="
                  active ? 'primary white--text text--lighten-1 ' : 'white'
                "
                v-on:click="subPlan='zLITE 3',price='10000',validity='1 year'"
                @click="toggle">
                <v-card-title class="pb-2">
                  <v-row>
                    <v-col cols="4" lg="12" class="h3 pt-1 pb-0 text-center">
                      <!-- <v-avatar color="primary" rounded size="30">1</v-avatar> -->
                      <h4 class="pt-2">zLITE 3</h4>
                    </v-col>
                    <v-col
                      cols="8"
                      lg="12"
                      class="float-right mt-1 pt-1 pb-0 text-end text-lg-center">
                      &#8377; 10000
                      <p class="caption" style="margin-top: -5px">
                        &nbsp;(1 year)
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-title class="pb-2">
                  <v-row>
                    <!-- v-bind:class="{ active: isActive }" style="color:white" -->
                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.005%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Intraday<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.05%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Delivery<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>&#8377;10</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Options<small>*</small>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
          <!-- card three end -->

          <!-- card four -->
          <v-col cols="12" lg="2">
            <v-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary white--text text--lighten-1 ' : 'white'"
                v-on:click="subPlan='zLITE 4',price='25000',validity='1 year'"
                @click="toggle">
                <v-card-title class="pb-2">
                  <v-row>
                    <v-col cols="4" lg="12" class="h3 pt-1 pb-0 text-center">
                      <!-- <v-avatar color="primary" rounded size="30">1</v-avatar> -->
                      <h4 class="pt-2">zLITE 4</h4>
                    </v-col>
                    <v-col
                      cols="8"
                      lg="12"
                      class="float-right mt-1 pt-1 pb-0 text-end text-lg-center">
                      &#8377; 25000
                      <p class="caption" style="margin-top: -5px">
                        &nbsp;(1 year)
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-title class="pb-2">
                  <v-row>
                    <!-- v-bind:class="{ active: isActive }" style="color:white" -->
                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.0035%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Intraday<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.03%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Delivery<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>&#8377; 5</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Options<small>*</small>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
          <!-- card four end -->

          <!-- card five -->
          <v-col cols="12" lg="2">
            <v-item v-slot="{ active, toggle }" >
              <v-card
                :color="active ? 'primary white--text text--lighten-1 ' : 'white'"
                v-on:click="subPlan='zLITE 5',price='50000',validity='1 year'"
                @click="toggle">
                <v-card-title class="pb-2">
                  <v-row>
                    <v-col cols="4" lg="12" class="h3 pt-1 pb-0 text-center">
                      <!-- <v-avatar color="primary" rounded size="30">1</v-avatar> -->
                      <h4 class="pt-2">zLITE 5</h4>
                    </v-col>
                    <v-col
                      cols="8"
                      lg="12"
                      class="float-right mt-1 pt-1 pb-0 text-end text-lg-center">
                      &#8377; 50000
                      <p class="caption" style="margin-top: -5px">
                        &nbsp;(1 year)
                      </p>
                    </v-col>
                  </v-row>
                </v-card-title>

                <v-card-title class="pb-2">
                  <v-row>
                    <!-- v-bind:class="{ active: isActive }" style="color:white" -->
                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px">
                        <div>0.002%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0">
                          Intraday<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px"
                      >
                        <div>0.02%</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0"
                        >
                          Delivery<small>*</small>
                        </p>
                      </div>
                    </v-col>

                    <v-col cols="4" lg="12" class="pb-0 pt-1">
                      <v-divider class="pb-1"></v-divider>
                      <div
                        class="text-center"
                        style="padding-top: 5px; padding-bottom: 5px"
                      >
                        <div>&#8377; 2</div>
                        <p
                          class="mt-n2 justify-center"
                          style="font-size: 12px; margin: 0"
                        >
                          Options<small>*</small>
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-item>
          </v-col>
          <!-- card five end -->
        </v-row>

        <v-row class="ml-lg-16 pl-lg-6">
          <!-- Bottom col 1 -->
          <v-col cols="12" lg="5" style="padding-top:35px;">
            <small class="caption"
              >*<strong> Intraday -</strong> Equity , Equity Futures , Currency
              & Commodity<br
            /></small>
            <small class="caption">*<strong> Delivery -</strong> Equity </small>
            <p class="caption  py-4">
              Note : The brokerage charged in the client account will be
              reversed to the client account on a daily basis to the extent of
              the amount subscribed and/or till validity of the subscription
              whichever is earlier.
            </p>
          </v-col>

          <!-- Bottom col 2 -->
          <v-col cols="12" lg="6" class=" pl-lg-16 " >
            <v-dialog
              v-model="dialog"
              fullscreen
              persistent
              hide-overlay
              transition="dialog-bottom-transition"
              @keydown.esc="dialog = true">
              
              <v-card outlined>
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Terms and Conditions</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-list two-line
                  class="overflow-y-auto" max-height="525">
                  <div class="px-8 pt-2">
                    <ul style="font-size: 0.85rem">
                      <li>
                        By signing this document, I/We explicitly provide our
                        unconditional consent to avail the Z-LITE SERVICES (ZLS)
                        mentioned in this document (benefits given under each
                        ZLS pack) and accept the Terms and Conditions that are
                        laid down by Zebu Share and Wealth Managements Private
                        Limited (herein after referred to as ‘Zebu’) for
                        subscribing to this ZLS Subscription Plans. Further,
                        I/We authorize Zebu to debit my/our account the ZLS
                        Subscription Charges and also give my/our consent for
                        debiting any other charges as and when they arise (or on
                        the due date for such amounts to be paid by me/us). I
                        further understand that the charges for the ZLS
                        Subscription Plans are subject to change and Zebu will
                        intimate the change 15 days in advance. I am also aware
                        that GST is applicable on the Annual ZLS Charges over
                        and above the Annual ZLS Charges stated in the ZLS
                        Subscription Plans, Benefits & Charges table given
                        above. <br />
                      </li>
                      <li>
                        Unless otherwise specified by Zebu, I understand and
                        agree that I am responsible and liable for the payment
                        of all taxes, duties, levies and charges imposed by the
                        regulatory authorities/exchanges or under any Law in
                        connection with the ZLS and also the other
                        brokerage/depository related charges that may be
                        announced from time-to-time.<br />
                      </li>
                      <li>
                        On signing and submission of this form to Zebu, I/we
                        understand that Zebu will immediately debit my/our
                        account (client trading ledger) with the required sum
                        towards the ZLS plan selected by me/us. I/We understand
                        that once the ZLS plan is selected/activated (by
                        debiting the client ledger) the money paid as
                        subscription towards the plan is NON-REFUNDABLE under
                        any circumstances.<br />
                      </li>
                      <li>
                        I/We further understand that ZLS subscription plan
                        selected by me will be automatically renewed upon the
                        expiry of the time period for which the Name Client Code
                        PAN subscription money is paid by me/us and the required
                        subscription amount that prevails (fixed by Zebu) at the
                        time of such renewal will be debited to my account
                        (client trading ledger) before 7 days of expiry of the
                        subscription period that is remains active. I/We
                        understand that once the ZLS plan is renewed (by
                        debiting the client ledger) the money paid towards
                        renewal of the plan is NON-REFUNDABLE under any
                        circumstances.<br />
                      </li>
                      <li>
                        I/We understand that if at any time, if I/We
                        deactivates/change the Z-LITE PACK (Z-LITE1, Z-LITE2,
                        Z-LITE3, Z-LITE4, Z-LITE5 packs), the brokerage rates
                        Plan/Structure changes automatically to the default
                        brokerage rates/plan levied by Zebu.<br />
                      </li>
                      <li>
                        I/We confirm that if I/we want to change/deactivate a
                        subscription plan I/we shall send an email from my/our
                        registered e-mail id to assist@zebuetrade.com. I/We
                        understand that in case of deactivation of plan no
                        refund of subscription amount paid by me will be given
                        (even though where there is a remaining subscription
                        period) to me/us by Zebu.<br />
                      </li>
                      <li>
                        I/We understand that in case if I/we want to upgrade to
                        a new ZLS plan (for example from Z-LITE1 to Z-LITE2, or
                        Z-LITE2 to Z-LITE3 or Z-LITE3 to Z-LITE4 or Z-LITE4 to
                        Z-LITE5) then I/we will have to pay the full
                        subscription charges payable towards the new plan
                        selected by me/us.<br />
                      </li>
                      <li>
                        I/We have fully understood the ‘ZLS Access Charges
                        Subscription’ and do hereby agree not to call into
                        question the validity, enforceability and applicability
                        of any provision/clauses this document any circumstances
                        whatsoever. I/We am/are aware that the terms and
                        conditions of ‘ZLS Access Charges Subscription’ may be
                        amended/changed by the Zebu from time to time, and the
                        changes will be informed to me/us through the registered
                        mobile number or email id (available in the records of
                        Zebu at the time of making such change). Further, I/we
                        agree not to challenge the same on any grounds including
                        delayed receipt/ non-receipt of such communication or
                        any other reasons whatsoever. The ‘ZLS Access Charges
                        Subscription’ and its terms and conditions shall always
                        be read along with the policies and procedures of Zebu
                        and also the earlier agreements signed by me with Zebu
                        prior to me/we signing the ‘ZLS Access Charges
                        Subscription’ terms and conditions (i.e., including that
                        of the agreements entered between the me/us at the time
                        of registering as client with Zebu or any subsequent
                        amendments) and shall be compulsorily referred to while
                        deciding any dispute, difference or claim between me and
                        Zebu before any court of law/judicial/adjudicating
                        authority including arbitrator/mediator etc.<br />
                      </li>
                    </ul>
                  </div>
                </v-list>
                  
                  <v-card-text class="text-center" style="width=100%;padding-top:10px;padding-bottom:0;">
                  <v-btn color="primary" @click="dialog = false"> Accept </v-btn>
                  </v-card-text>
              </v-card>
            </v-dialog>
            
            <v-card outlined v-if="loginWindow" :loading='cardloader'>
              <v-card-title>Login to Subscribe zLITE</v-card-title>
              <v-card-text>
              <div v-if="clientcodeDisp" style="padding:10px;padding-top:0;padding-bottom:20px;">
              
                  <v-text-field
                    v-if="!isHidden"
                    v-model="clientcode"
                    label="Client Code"
                    :error-messages="codeError"
                    @input="$v.clientcode.$touch()"
                    @blur="$v.clientcode.$touch()"
                  
                    required
                    @keyup="clientcode=clientcode.toUpperCase()"
                  ></v-text-field>
                   <v-text-field
                    v-if="!isHidden"
                    v-model="pan"
                    label="PAN"
                    :error-messages="panDError"
                    @input="$v.pan.$touch()"
                    @blur="$v.pan.$touch()"
                    required
                    @keyup="pan=pan.toUpperCase()"
                    counter="10"
                    maxlength=10
                  ></v-text-field>
                  <!-- <p v-if="clientcodeError" style="font-size:12px;color:red">Invalid clientcode please check</p>
                  <p v-if="panError" style="font-size:12px;color:red">Invalid PAN please check</p> -->
                <v-btn 
                    :disabled="cardloader"
                    color="primary"
                    block
                    @click="login()"
                    style="margin-top:12px;"
                > Login
                      </v-btn>
           
                
              </div>

              <div v-if="clientcodeDisp" style="font-size:12px;">Don't have an account yet? <a href="https://zkyc.zebull.in/zebuetrade/signup" target="_blank">Sign up Zebu</a></div>
              <v-row v-if="otpDisp"  style="padding:10px;padding-bottom:20px;">
                <v-col cols="12" lg="9" style="padding:0;">
                  <v-text-field
                    v-if="!isHidden"
                    v-model="otp"
                    label="OTP"
                    :error-messages="otpError"
                    @input="$v.otp.$touch()"
                    @blur="$v.otp.$touch()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" style="padding:0;">
                
                <v-btn 
                    
                    :loading="loading"
                    :disabled="loading"
                    color="secondary"
                    block
                    @click="loader = 'loading',otpVerify()"
                    style="margin-top:12px;"
                    
                  > Verify
                      </v-btn>
                </v-col>
                <!--v-on:click="isHidden = !isHidden"  -->
                
              </v-row>
              <div v-if="otpDisp" style="font-size:12px;">OTP has sent. Not recieved yet? <a>Resend</a></div>
              </v-card-text>
            </v-card>
            <v-card outlined v-if="subWindow" :loading='cardloader'>
                <v-card-title>Subscription <v-spacer></v-spacer> <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="logout()"
                    >
                      <v-icon color="darken-2">mdi-logout-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Log Out</span>
                </v-tooltip></v-card-title>
                <v-card-text>Hi <strong>{{ clientname }}</strong> <br>You have opted for the subscription plan <strong>{{ subPlan }}</strong> 
                    <br> Price - <strong>&#8377;{{ price }}</strong>, Validity - <strong>{{ validity }}</strong>
                    <v-checkbox v-model="subAgree" @click="termsandcondition()">
                      <template v-slot:label>
                        <div>
                          I agree that Terms &amp; Conditions
                        </div>
                      </template>
                    </v-checkbox>
                <v-btn color="primary" block @click="subscribe()" :disabled="!subAgree">Subscribe {{ subPlan }}</v-btn></v-card-text>
            </v-card>
            <v-card outlined v-if="existsubWindow" :loading='cardloader'>
                <v-card-title style="width:100%">Subscribed<v-spacer></v-spacer> 
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                                    color="grey darken-2"
                                    style="float:right;"
                                    @click="sheet=true"
                                  >
                                    mdi-history
                                  </v-icon>
                    </v-btn>
                  </template>
                  <span>Subscription History</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="logout()"
                    >
                      <v-icon color="darken-2">mdi-logout-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Log Out</span>
                </v-tooltip>

    </v-card-title>
                <v-card-text>Hi <strong>{{ clientname }}</strong> <br>You have already subscribed <strong>{{ selectedPlan }}</strong> 
                    <br> Expires on <strong>{{ expiredate }}</strong><br> you have <strong>{{ dayleft }} days</strong> left!
                    <v-checkbox v-if="subPlan != selectedPlan"  v-model="subexistAgree" @click="termsandcondition()">
                      <template v-slot:label>
                        <div>
                          I agree that Terms &amp; Conditions
                        </div>
                      </template>
                    </v-checkbox>
                <v-btn color="primary" v-if="subPlan != selectedPlan" block @click="subscribe(),switchsub='Yes'" :disabled="!(subexistAgree && subPlan != selectedPlan)">Switch {{ subPlan }}</v-btn>
                <div v-if="subPlan == selectedPlan" style="padding-top:20px;">
                <v-btn class="text-white" color="black" style="color:white;" @click="subscribe(),renew='Yes'" block>Top-up</v-btn>
                </div>
                </v-card-text>
            </v-card>
            <v-card outlined v-if="otpVerifyWindow" :loading='cardloader'>
                <v-card-title>Verify OTP<v-spacer></v-spacer>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon
                                    color="grey darken-2"
                                    style="float:right;"
                                    @click="backtohome()"
                                  >
                                    mdi-arrow-left
                                  </v-icon>
                    </v-btn>
                  </template>
                  <span>Back</span>
                </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <p  style="font-size:12px;">OTP has sent to registered Mobile Number</p>
                  <v-otp-input
                    v-model="otp"
                    length="4"
                    plain
                  ></v-otp-input>
                  <p v-if="otpErrorMsg" style="font-size:12px;color:red">Invalid otp!</p>
                  <v-btn color="primary" block @click="otpVerify()" :disabled="cardloader">Verify</v-btn>
                  <p style="font-size:12px;">OTP has sent. Not recieved yet? <a>Resend</a></p>
                </v-card-text>
            </v-card>
            <v-card outlined v-if="status" class="text-center">
              <v-card-text><h2>Congratulations!</h2></v-card-text>
              <v-icon class="mx-auto" size="70" color="green" width="250">mdi-checkbox-marked-circle</v-icon>
              <v-card-text>You have {{ sub_status }}
               <strong>{{ selectedPlan }}</strong>  has been confirmed.</v-card-text>
               
              <v-card-text><v-btn block color="primary" style="padding:10px;" @click="backtohome()">Back to Home</v-btn></v-card-text>
            </v-card>
            
          </v-col>

        </v-row>
      </v-container>

    </v-item-group>
    <v-bottom-sheet
      v-model="sheet"
      persistent
      width="800px"
    >

      <v-sheet
        
        
      >
         <v-card-title> Subscription history
           <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="sheet = !sheet"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn></v-card-title>
        <div class="my-1">
          <v-data-table
            dense
            :headers="headers"
            :items="desserts"
            item-key="name"
            :items-per-page="5"
            class="elevation-1"
          ></v-data-table>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
  
</template>

<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: "Subscription",
  mixins:[validationMixin],
  validations:{
      clientcode : { required },
      otp : { required },
      pan : { required }  
  },
  data() {
    return {
      sheet:false,
      isHidden: false,
      dialog: false,
      clientcode:null,
      pan:null,
      otp:null,
      loader: null,
      clientcodeDisp:true,
      clientname:null,
      otpDisp:false,
      loading: false,
      selectedCard:null,
      loginWindow:true,
      subPlan:"zLITE 1",
      selectedPlan:null,
      clientcodeError:false,
      panError:false,
      subWindow:false,
      otpVerifyWindow:false,
      subAgree:false,
      subexistAgree:false,
      price:"2500",
      selectedPrice:null,
      validity:"6 months",
      selectedValidity:null,
      status:false,
      otpErrorMsg:false,
      expiredate:null,
      dayleft:null,
      switchsub:'No',
      renew:'No',
      existsubWindow:false,
      cardloader:false,
      sub_status:null,
      disableHostCheck: true,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value && value.length >= 4) || "Min 4 otp number",
      ],
      desserts: null,
      headers: [
        {
          text: 'Plan',
          align: 'start',
          sortable: false,
          value: 'plan',
        },
        { text: 'Price', value: 'price' },
        { text: 'Subscribed Date', value: 'sub' },
        { text: 'Expiry Date', value: 'exp'},
        { text: 'Period', value: 'period' },
        { text: 'Validity', value: 'val' },
        { text: 'Status', value: 'status' },
      ],
    };
  },
  methods:{
      login(){
        this.cardloader = true
        var axiosthis = this
        const now = new Date();
        
        this.$v.$touch();
        
        //const ttl = 15;
        //axios.post('https://be.zebull.in/client_check?code='+this.code)
        //console.log("TIME :"+now.getSeconds(),now.setSeconds(now.getSeconds() + 900))
        if(this.clientcode){
          axios.post('https://bo.zebull.in/staticdata/wsdl/JSON_searchpan_v1.cfm?USERNAME=api&password=api@54321&search_on=client_id&value='+this.clientcode)
          .then(function (response) {
              //console.log(response.data);
              //this.resp = response.data;
              //axiosthis.$v.$reset();
              if(response.data.length <=29 && typeof(response.data) == 'string'){ 
                  //console.log('Client code error'+response.data);
                  axiosthis.clientcodeError = true;
                  axiosthis.panError = false;
                  axiosthis.cardloader=false
              }
              else{
                  axiosthis.clientcodeError = false;
                  //console.log('Resp:',response.data['DATA'][0][1]);
                  var pan = response.data['DATA'][0][1];
                  axiosthis.clientname=response.data['DATA'][0][2];
                  if(axiosthis.pan){
                      if (axiosthis.pan == pan){
                      //console.log('Pan error');
                      axiosthis.panError = false;
                      localStorage.setItem('hash',btoa(axiosthis.clientcode+','+axiosthis.pan+','+now.setSeconds(now.getSeconds() + 900)));
                      axiosthis.checksubscription();
                      axiosthis.$v.$reset();
                    }
                    else{
                      axiosthis.panError = true;
                    }
                  }
                  axiosthis.cardloader = false;
              }
          })
          .catch(function (error) {
            alert(error);
          });
        }
        else{
          this.cardloader = false
        }

      },
      checksubscription(){
                                            var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/checksub?code='+this.clientcode,
                                              headers: { }
                                            };
                                            var axiosthis = this

                                            axios(config)
                                            .then(function (response) {
                                              //console.log(JSON.stringify(response.data));
                                              if(response.data['status'] == 'ok'){
                                                      axiosthis.loginWindow  =false;
                                                      axiosthis.subWindow = false;
                                                      axiosthis.otpVerifyWindow = false;
                                                      axiosthis.otpVerifyWindow = false;
                                                      axiosthis.selectedPlan = response.data['plan']
                                                      var exp = (response.data['validity']).split('GMT')[0].split(' '); 
                                                      axiosthis.expiredate = exp[2]+ ' ' + exp[1] + ' ' + exp[3]
                                                      //console.log(typeof(response.data['validity']))
                                                      axiosthis.dayleft = response.data['dayleft']
                                                      axiosthis.existsubWindow = true;
                                                      axiosthis.desserts = response.data['history']
                                              }
                                              else{
                                                      axiosthis.loginWindow  =false;
                                                      axiosthis.subWindow = true;
                                                      axiosthis.otpVerifyWindow = false;
                                                      axiosthis.existsubWindow = false;

                                              }

                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                            });
      },
      subscribe(){
                                        this.$v.$touch();
                                        this.cardloader = true;
                                        //console.log("Client Code Sent :"+this.clientcode)
                                        var hash = new Date().toLocaleString()
                                        localStorage.setItem('sesslog',btoa(hash))
                                        this.selectedPlan = this.subPlan
                                        this.selectedPrice = this.price
                                        this.selectedValidity = this.validity
                                        var axiosthis = this
                                        if (this.clientcode){
                                            var config = {
                                              method: 'post',
                                              url: 'https://be.zebull.in/clientcode?code='+this.clientcode+'&hash='+btoa(hash),
                                              headers: { }
                                            };
                                            

                                            axios(config)
                                            .then(function (response) {
                                              //console.log(JSON.stringify(response.data));
                                              if(response.data['status'] == 'ok'){
                                                      axiosthis.loginWindow  =false;
                                                      axiosthis.subWindow = false;
                                                      axiosthis.existsubWindow = false;
                                                      axiosthis.otpVerifyWindow = true;
                                                      axiosthis.cardloader = false;
                                              }

                                            })
                                            .catch(function (error) {
                                              console.log(error);
                                              axiosthis.cardloader = false;
                                            });
                                        }
                                        else{
                                            //this.errorMobNumber=true
                                            console.log('Error!')
                                        }
      },
      otpVerify(){
            this.$v.$touch();
            var axiosthis =this
            axiosthis.cardloader = true;
            this.otpErrorMsg =false;
            var config = {
                           method: 'post',
                           url: 'https://be.zebull.in/zliteotp?code='+this.clientcode+'&hash='+localStorage.getItem('sesslog')+'&otp='+this.otp+'&plan='+this.selectedPlan+'&validity='+this.selectedValidity+'&price='+this.selectedPrice+'&switch='+this.switchsub+'&renew='+this.renew,
                           headers: { }
                        };
                         axios(config)
                         .then(function (response) {
                                //console.log(JSON.stringify(response.data));
                                if(response.data['status'] == 'ok'){
                                        axiosthis.loginWindow  =false;
                                        axiosthis.subWindow = false;
                                        axiosthis.otpVerifyWindow = false;
                                        axiosthis.existsubWindow = false;
                                        axiosthis.status=true;
                                        if(axiosthis.switchsub == 'Yes'){
                                          axiosthis.sub_status = "Switched"
                                        }
                                        else if(axiosthis.renew == 'Yes'){
                                          axiosthis.sub_status = "Top-up"
                                        } 
                                        else{
                                          axiosthis.sub_status = "Subscribed"
                                        }
                                        axiosthis.switchsub='No'
                                        axiosthis.renew='No'
                                        //axiosthis.$v.$reset();
                                        axiosthis.cardloader = false;
                                }
                                else{
                                    axiosthis.otp=null
                                    axiosthis.otpErrorMsg = true
                                    axiosthis.cardloader = false;
                                }

                          })
                          .catch(function (error) {
                                        console.log(error);
                                        axiosthis.cardloader = false;
                          });

      },
      termsandcondition(){
            //console.log("checkbox :",this.subAgree);
            //this.subAgree=!this.subAgree;
            if(this.subAgree || this.subexistAgree){
              this.dialog=true;
              //this.subAgree= this.subAgree.filter(false);
            }
      },
      logout(){
        localStorage.removeItem('hash');
        Object.assign(this.$data, this.$options.data.call(this));

      },
      backtohome(){
        
        if (localStorage.getItem('hash')){
          
          var hash = atob(localStorage.getItem('hash')).split(',');
          //console.log("Hash Retry : "+hash)
          const now = new Date()
          // compare the expiry time of the item with the current time
          //console.log("TIME :"+now.getSeconds(),hash[2],hash[0],hash[1])
          if (now.getTime() > hash[2]) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem('hash')
            //return null
          }
          else{ 
              this.clientcode = hash[0]
              this.pan = hash[1]
              this.status = false
              this.subAgree = false
              this.subexistAgree = false
              this.otpErrorMsg =false;
              this.otp = null
              this.login()
          }  
        }

      }
  },
  mounted(){
      
      if (localStorage.getItem('hash')){
        var hash = atob(localStorage.getItem('hash')).split(',');
        //console.log("Hash Retry : "+hash)
        const now = new Date()
        // compare the expiry time of the item with the current time
        //console.log("TIME :"+now.getSeconds(),hash[2],hash[0],hash[1])
        if (now.getTime() > hash[2]) {
          // If the item is expired, delete the item from storage
          // and return null
          localStorage.removeItem('hash')
          return null
        }
        else{ 
            this.clientcode = hash[0]
            this.pan = hash[1]
            this.status = false
            this.subAgree = false
            this.subexistAgree = false
            this.otpErrorMsg =false;
            this.login()
        }  
      }
  },
  computed :{
        codeError(){
          const errors = []
          //console.log(this.clientcodeError)
          if(this.clientcodeError){
              errors.push('Invalid Client Code')
              return errors;
          }
          else{
            if(!this.$v.clientcode.$dirty) return errors
            !this.$v.clientcode.required && errors.push('Clientcode is required')
            return errors
          }
        },
        otpError(){
          const errors = []
          if(!this.$v.otp.$dirty) return errors
          !this.$v.otp.required && errors.push('OTP is required')
          return errors
        },
        panDError(){
          const errors = []
          if(this.panError){
              errors.push('Invalid PAN Number')
              return errors;
          }
          else{ 
            if(!this.$v.pan.$dirty) return errors
            !this.$v.pan.required && errors.push('PAN is required')
            return errors
          }
        }
  },

   watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      },
    },
};
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>